import { Application } from 'stimulus'
import { install } from '@/controllers/resolver'
import { context } from '@/controllers/public'
import { context as commonContext } from '@/controllers/common'
import { tabIdentifier } from '@/factories/cable_ready_channel'

import mrujs from 'mrujs'

import '@/channels/public'
import './common'
import 'intersection-observer'

import '~/stylesheets/public/index.scss'

const application = Application.start()

install(application, context, 'public')
install(application, commonContext, 'common')

document.addEventListener(
  'ajax:beforeSend',
  (e: Event) => {
    const { request } = (
      e as CustomEvent<ReturnType<typeof mrujs.FetchRequest>>
    ).detail
    request.headers.append('X-Tab-Identifier', tabIdentifier)
  },
  { capture: true }
)